import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import Button from '../common/Button';
import Text from '../common/Text';
import { ReturnConfirmationViewProps } from './types';
import { useSettings } from '../../hooks/useSettings';
import PageView from '../PageView';
import ConfirmationContactUs from './ConfirmationContactUs';
import CreateAdditionalLabel from './CreateAdditionalLabel';
import { useGeneralConfirmationMessage } from './useConfirmationMessage';
import useReturnPortalType from '../../hooks/useReturnPortalType';
import ConfirmationProductsList from './ConfirmationProductsList';
import { ReturnRequest } from '../../types';

const ButtonLink = styled('a')`
  text-decoration: none;
  width: 100%;
  display: block;
  max-width: 520px;
  margin-bottom: 24px;
`;

function NormalConfirmationView(props: ReturnConfirmationViewProps) {
  const { accentColor } = useSettings();
  const { returnRequest, returnState } = props;
  const { t } = useTranslation();
  const { items } = returnRequest;
  const { isLite } = useReturnPortalType();

  const message = useGeneralConfirmationMessage(
    isLite ? 'confirmationLite_message' : 'confirmation_message',
    returnState,
    returnRequest
  );

  const buttonText = getButtonText(returnRequest);
  const instructions = getInstructionsText(returnRequest);

  return (
    <PageView heading="confirmation_heading" message={message} intent="success">
      <ConfirmationContactUs />
      <ButtonLink href={returnRequest.labelUrl} target="_blank" rel="noopener noreferrer">
        <Button name="downloadLabel" fullWidth color={accentColor}>
          {t(buttonText)}
        </Button>
      </ButtonLink>
      <Text secondary>{t(instructions)}</Text>
      {returnState === 'exists' && <CreateAdditionalLabel color={accentColor} />}
      {!isLite && <ConfirmationProductsList heading="confirmation_productsListHeading" items={items} />}
    </PageView>
  );
}

function getButtonText(returnRequest: ReturnRequest): string {
  if (returnRequest.isLabelHomePickup) {
    return 'downloadHomePickupLabelButton';
  }

  return returnRequest.isLabelPrinterless ? 'downloadPrinterlessLabelButton' : 'downloadStandardLabelButton';
}

function getInstructionsText(returnRequest: ReturnRequest): string {
  if (returnRequest.isLabelHomePickup) {
    return 'homePickupLabelInstructions';
  }

  return returnRequest.isLabelPrinterless ? 'printerlessLabelInstructions' : 'standardLabelInstructions';
}

export default React.memo(NormalConfirmationView);
