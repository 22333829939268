import React from 'react';
import styled from '@emotion/styled';

import ProductImage from './ProductImage';
import ProductInfo from './ProductInfo';

const Container = styled('div')`
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
`;

const Overlay = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
`;

export type Item = {
  name: string;
  size: string;
  price: number;
  currency: string;
  productImageUrl?: string;
  totalQuantity: number;
};

type Props = Item;

function NonReturnableProductItem(props: Props) {
  const { productImageUrl, name, size, price, currency, totalQuantity } = props;

  return (
    <Container data-testid="product-item">
      <ProductImage url={productImageUrl} />
      <ProductInfo
        name={name}
        size={size}
        price={price}
        notReturnable={true}
        currency={currency}
        quantity={totalQuantity}
        isQuantityHidden={totalQuantity === 1}
      />
      <Overlay data-testid="not-eligible-overlay" />
    </Container>
  );
}

export default React.memo(NonReturnableProductItem);
