import React from 'react';

import Logo from './Logo';
import { useSettings } from '../../hooks/useSettings';

function PageLogo() {
  const { logoImageUrl, logoLink } = useSettings();

  return <Logo imageUrl={logoImageUrl} imageLink={logoLink} align="left" />;
}

export default React.memo(PageLogo);
