import React from 'react';
import styled from '@emotion/styled';

import colors from '../common/colors';
import { LayoutWithActionProps } from './types';
import MobilePadding from '../paddings/MobilePadding';
import StickyButtonContainer from './StickyButtonContainer';

const Container = styled('div')`
  width: 100%;
`;

const BodyContainer = styled(MobilePadding)`
  background-color: ${colors.white};
  box-sizing: border-box;
  padding-bottom: 20px;
`;

function MobileLayout(props: LayoutWithActionProps) {
  const { heading, info, content, button } = props;

  return (
    <Container>
      <BodyContainer>
        {heading}
        {info}
        {content}
      </BodyContainer>
      <StickyButtonContainer>
        <MobilePadding>{button}</MobilePadding>
      </StickyButtonContainer>
    </Container>
  );
}

export default React.memo(MobileLayout);
