import React from 'react';
import styled from '@emotion/styled';

import colors from '../common/colors';
import { LayoutWithActionProps } from './types';
import TabletPadding from '../paddings/TabletPadding';
import StickyButtonContainer from './StickyButtonContainer';
import HorizontalSeparator from './HorizontalSeparator';

const BodyContainer = styled(TabletPadding)`
  background-color: ${colors.white};
  box-sizing: border-box;
  padding-bottom: 32px;
`;

const ContentContainer = styled('div')`
  padding-top: 28px;
`;

function TabletLayout(props: LayoutWithActionProps) {
  const { heading, info, content, button } = props;

  return (
    <>
      <BodyContainer>
        {heading}
        {info}
        <ContentContainer>{content}</ContentContainer>
      </BodyContainer>
      <StickyButtonContainer>
        <TabletPadding>
          <HorizontalSeparator />
          {button}
        </TabletPadding>
      </StickyButtonContainer>
    </>
  );
}

export default React.memo(TabletLayout);
