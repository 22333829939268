import React from 'react';
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';

import { CreateAdditionalLabelProps } from './types';
import useSubsequentReturn from '../../hooks/useSubsequentReturn';

const Container = styled('div')`
  width: 100%;
  font-size: 16px;
  margin: 32px 0;
  line-height: 24px;
`;

type ButtonProps = {
  color: string;
  children: React.ReactNode;
};

const StyledButton = styled('button')<ButtonProps>`
  color: ${props => props.color};
  text-decoration: none;
  border: none;
  background: none;
  font-size: 16px;
  cursor: pointer;
  padding: 0;
  margin-left: 4px;

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
`;

function CreateAdditionalLabel(props: CreateAdditionalLabelProps) {
  const { color } = props;
  const { isLabelAvailable, createLabel, error, isLoading } = useSubsequentReturn();

  useTranslation();

  if (!isLabelAvailable) {
    return null;
  }

  return (
    <Container>
      <Trans>confirmation_additionalLabelText</Trans>
      <StyledButton name="createSubsequentLabel" color={color} disabled={isLoading} onClick={createLabel}>
        <Trans>{error ? 'confirmation_additionalLabelTryAgainButton' : 'confirmation_additionalLabelButton'}</Trans>
      </StyledButton>
      {'.'}
    </Container>
  );
}

export default React.memo(CreateAdditionalLabel);
