import React from 'react';
import styled from '@emotion/styled';

interface Props {
  fullHeight?: boolean;
  imageUrl?: string;
}

const Banner = styled('div')<Props>`
  width: 100%;
  height: ${props => (props.fullHeight ? '100%' : '100px')};
  max-height: ${props => (props.fullHeight ? '100%' : '100px')};
  flex-shrink: 0;
  background-image: url(${(props: Props) => props.imageUrl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export default React.memo(Banner);
