import React from 'react';
import styled from '@emotion/styled';

import colors from '../common/colors';
import { ViewLayoutProps } from './types';
import DesktopPadding from '../paddings/DesktopPadding';

const Content = styled(DesktopPadding)`
  background-color: ${colors.white};
  padding-top: 32px;
  padding-bottom: 32px;
`;

function DesktopViewLayout(props: ViewLayoutProps) {
  const { heading, info, message, children } = props;

  return (
    <>
      {heading}
      <Content>
        {info}
        {message}
        {children}
      </Content>
    </>
  );
}

export default DesktopViewLayout;
