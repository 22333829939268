import React from 'react';
import styled from '@emotion/styled';

import ProductsListHeading from './ProductsListHeading';
import NonReturnableProductsList from './NonReturnableProductList';
import { GroupedReturnItem } from '../../types';

type Props = {
  items: GroupedReturnItem[];
};

const Container = styled('div')`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
`;

function NonReturnableProducts(props: Props) {
  const { items } = props;

  if (items.length === 0) {
    return null;
  }

  return (
    <Container>
      <ProductsListHeading>nonReturnableProductsListHeading</ProductsListHeading>
      <NonReturnableProductsList items={items} />
    </Container>
  );
}

export default React.memo(NonReturnableProducts);
