declare global {
  interface Window {
    heap: {
      addEventProperties(properties: any): void;
    };
  }
}

export function heapAddEventProperties(properties: any) {
  window.heap?.addEventProperties(properties);
}
