import { LanguageTranslations, ReasonOption, ReturnPortalTranslations } from './types';

export function transformReasonOptionsToTranslations(reasonOptions: Array<ReasonOption> = []): LanguageTranslations {
  return reasonOptions.reduce<LanguageTranslations>((acc, option) => ({ ...acc, [option.value]: option.label }), {});
}

export function mergeTranslationsWithReasons(
  reasonLanguage: string,
  translations: ReturnPortalTranslations = {},
  reasonOptions: Array<ReasonOption> = []
) {
  const reasonTranslations = transformReasonOptionsToTranslations(reasonOptions);

  return {
    ...translations,
    [reasonLanguage]: {
      ...translations[reasonLanguage],
      ...reasonTranslations,
    },
  };
}
