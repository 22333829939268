import React from 'react';

import Column from '../common/layout/Column';
import ProductItem from './ProductItem';
import { GroupedReturnItem } from '../../types';

type Props = {
  items: GroupedReturnItem[];
};

function ProductsList(props: Props) {
  const { items } = props;

  return (
    <Column spacing={24}>
      {items.map(item => (
        <ProductItem key={item.productKey} {...item} />
      ))}
    </Column>
  );
}

export default React.memo(ProductsList);
