import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

import LoginPage from '../login/LoginPage';
import ReturnOverviewPage from '../products-overview/ReturnOverviewPage';
import ReturnConfirmationPage from '../confirmation/ReturnConfirmationPage';
import DeepLinkLogin from '../login/DeepLinkLogin';
import { ReturnPortalContext } from '../../contexts/ReturnPortalContext';
import { Switch } from 'react-router';
import Error404Page from '../error/Error404Page';

function Routes() {
  const { hasBrandedUrl } = useContext(ReturnPortalContext);
  const PortalRoutes = hasBrandedUrl ? BrandedUrlRoutes : GeneralUrlRoutes;

  return <PortalRoutes />;
}

function GeneralUrlRoutes() {
  return (
    <Switch>
      <Route exact path="/:hash" component={DeepLinkLogin} />
      <Route path="/:hash/login" component={LoginPage} />
      <Route path="/:hash/return-overview" component={ReturnOverviewPage} />
      <Route path="/:hash/return-confirmation" component={ReturnConfirmationPage} />
      <Route component={Error404Page} />
    </Switch>
  );
}

function BrandedUrlRoutes() {
  // We put /:route? at the end because we want to prevent this route
  // from matching URLs with subdomain but without route (like https://shop.return-parcel.com/return-overview)
  // Might be in future would be better have 3 route groups (general, branded with route and branded without route)

  return (
    <Switch>
      <Route exact path="/:route?/pages/login" component={LoginPage} />
      <Route exact path="/:route?/pages/return-overview" component={ReturnOverviewPage} />
      <Route exact path="/:route?/pages/return-confirmation" component={ReturnConfirmationPage} />
      <Route exact path="/:route?" component={LoginPage} />
      <Route component={Error404Page} />
    </Switch>
  );
}

export default Routes;
