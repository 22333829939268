import { useEffect } from 'react';
import { useAsyncFn } from 'react-use';

import useUnauthorizedRedirect from './useUnauthorizedRedirect';
import { ReturnRequest } from '../types';
import { getReturnRequest } from '../api';

function useReturnRequest(returnRequestId?: number | null): [ReturnRequest | undefined, boolean, Error | undefined] {
  const [state, fetchReturnRequest] = useAsyncFn(returnRequestId => getReturnRequest(returnRequestId), []);
  const { value, error, loading } = state;

  useUnauthorizedRedirect(error);

  useEffect(() => {
    returnRequestId && fetchReturnRequest(returnRequestId);
  }, [returnRequestId, fetchReturnRequest]);

  return [value, loading, error];
}

export default useReturnRequest;
