import React from 'react';
import { useTranslation } from 'react-i18next';

import PageView from '../PageView';
import ConfirmationContactUs from './ConfirmationContactUs';
import ConfirmationProductsList from './ConfirmationProductsList';
import useReturnPortalType from '../../hooks/useReturnPortalType';
import { ReturnConfirmationViewProps } from './types';

function PendingConfirmationView(props: ReturnConfirmationViewProps) {
  const { returnRequest } = props;
  const { customerEmail, items } = returnRequest;
  const { t } = useTranslation();
  const { isLite } = useReturnPortalType();

  const message = t('confirmationPending_message', { customerEmail });

  return (
    <PageView heading="confirmationPending_heading" message={message} intent="success">
      <ConfirmationContactUs />
      {!isLite && <ConfirmationProductsList heading="confirmationPending_productsListHeading" items={items} />}
    </PageView>
  );
}

export default React.memo(PendingConfirmationView);
