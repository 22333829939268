import React from 'react';
import { useHistory } from 'react-router-dom';

import LoginView from './LoginView';
import useWithBaseUrl from '../../hooks/useWithBaseUrl';
import useShopId from '../../hooks/useShopId';
import useAuth from '../../hooks/useAuth';

import useChangeLanguage from '../../hooks/useChangeLanguage';

type Props = {
  selectedLanguage?: string;
  changeLanguage?: (language: string) => void;
};

function LoginPage(props: Props) {
  const history = useHistory();
  const overviewUrl = useWithBaseUrl(`/return-overview`);
  const { error, isLoading, login } = useAuth();
  const shopId = useShopId();

  const { changeLanguage, selectedLanguage } = useChangeLanguage();

  const onSubmit = (orderId: string, email: string) => {
    login({ orderId, email, shopId }).then((success: boolean) => {
      if (success) {
        history.push(overviewUrl);
      }
    });
  };

  return (
    <LoginView
      hasError={!!error}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onLanguageChange={changeLanguage}
      selectedLanguage={selectedLanguage}
    />
  );
}

export default React.memo(LoginPage);
