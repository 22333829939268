import styled from '@emotion/styled';

import colors from './colors';

const Heading2 = styled('h2')`
  color: ${colors.black};
  font-family: inherit;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin: 0;
`;

export default Heading2;
