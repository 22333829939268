import React from 'react';

import { ViewLayoutProps } from './types';
import TabletPadding from '../paddings/TabletPadding';

function TabletViewLayout(props: ViewLayoutProps) {
  const { info, heading, message, children } = props;

  return (
    <TabletPadding>
      {heading}
      {info}
      {message}
      {children}
    </TabletPadding>
  );
}

export default TabletViewLayout;
