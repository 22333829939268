import React, { ChangeEvent, useCallback } from 'react';
import styled from '@emotion/styled';
import colors from './colors';
import { Trans, useTranslation } from 'react-i18next';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  height: 64px;
`;

const Label = styled('label')`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.black};
`;

type StyleProps = {
  color: string;
};

const StyledInput = styled('input')<StyleProps>`
  min-height: 40px;
  line-height: 24px;
  font-size: 16px;
  font-family: inherit;
  border-radius: 4px;
  margin-top: 4px;
  padding: 8px 16px;
  border: 1px solid #dbdce0;
  outline: none;
  box-sizing: border-box;

  &:focus {
    border: 2px solid ${props => props.color};
    padding: 7px 15px;
  }
`;

export interface Props {
  name?: string;
  label?: string;
  onChange: (value: string, name?: string) => void;
  value?: string;
  placeholder?: string;
  type?: string;
  color: string;
}

function Input(props: Props) {
  const { name, label, onChange, value, placeholder = '', type } = props;

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value, name);
    },
    [onChange, name]
  );

  const { t } = useTranslation();

  return (
    <Container>
      <Label>
        <Trans>{label}</Trans>
        <StyledInput
          name={name}
          value={value}
          onChange={onChangeHandler}
          placeholder={t(placeholder)}
          type={type}
          color={colors.blue}
        />
      </Label>
    </Container>
  );
}

export default React.memo(Input);
