import React from 'react';
import styled from '@emotion/styled';
// @ts-ignore
import { CircularLoading } from 'respinner';

import colors from './colors';

const Container = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {
  color?: string;
};

function Spinner(props: Props) {
  return (
    <Container data-testid="loader">
      <CircularLoading stroke={props.color || colors.white} />
    </Container>
  );
}

export default Spinner;
