import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';

import Heading2 from '../common/Heading2';
import Text from '../common/Text';
import ActionButton from './ActionButton';
import Heading3 from '../common/Heading3';
import { OverviewReturnItem } from '../../types';

const Counter = styled('div')`
  margin: 4px 0 16px 0;
`;

type Props = {
  hasError?: boolean;
  isLoading?: boolean;
  selectedItems: OverviewReturnItem[];
  onButtonClick: () => void;
  isDesktop: boolean;
  buttonText: string;
  buttonName: string;
};

function ItemsCountWithAction(props: Props) {
  const { selectedItems, onButtonClick, isLoading, hasError, isDesktop, buttonText, buttonName } = props;
  const { t } = useTranslation();
  const Heading = isDesktop ? Heading2 : Heading3;
  const selectedItemsCount = useMemo(() => getSelectedItemsCount(selectedItems), [selectedItems]);

  return (
    <>
      <Heading>
        <Trans>overview_createReturnHeading</Trans>
      </Heading>
      <Counter data-testid="items-counter">
        <Text secondary>{t('overview_selectedItemsCount', { count: selectedItemsCount })}</Text>
      </Counter>
      <ActionButton
        name={buttonName}
        isDisabled={selectedItemsCount === 0}
        onClick={onButtonClick}
        isLoading={isLoading}
        hasError={hasError}
      >
        {buttonText}
      </ActionButton>
    </>
  );
}

function getSelectedItemsCount(items: OverviewReturnItem[]): number {
  return items.reduce((acc, item) => {
    if (item.totalQuantity > 1) {
      return acc + (item.quantity || 0);
    }

    return acc + 1;
  }, 0);
}

export default React.memo(ItemsCountWithAction);
