import { Order, ReturnRequest, ReturnCarrierService, OverviewCompletionState } from '../types';
import { useSettings } from './useSettings';
import { isConflictError } from '../apiHelpers';
import { isReturnPeriodExpired } from '../orderHelpers';
import { useReturnPortalCountry } from './useReturnPortalCountry';
import { SubmitState } from './useReturnRequestActions';
import useReturnPortalType from './useReturnPortalType';

type StateType =
  | 'empty'
  | 'create'
  | 'update'
  | 'next'
  | 'create-without-items'
  | 'next-without-items'
  | 'carrier-selection'
  | 'no-eligible-items'
  | 'expired'
  | 'return-not-supported'
  | 'return-already-created'
  | 'order-not-delivered'
  | 'order-country-mismatch'
  | 'confirmation-redirect'
  | 'external-redirect'
  | 'lite';

export type Params = {
  order?: Order;
  returnRequest?: ReturnRequest;
  submitState: SubmitState;
  overviewCompletionState: OverviewCompletionState;
  returnCarrierServices?: ReturnCarrierService[];
};

// TODO: we need to do something with this awful gordian knot of conditions >.<
function useOverviewState(params: Params): StateType {
  const { order, returnRequest, submitState, overviewCompletionState, returnCarrierServices = [] } = params;
  const { submittedReturnRequest, error: submitError } = submitState;

  const settings = useSettings();
  const returnPortalCountry = useReturnPortalCountry();
  const { isLite } = useReturnPortalType();

  if (overviewCompletionState.type === 'completed' && !submittedReturnRequest) {
    return 'carrier-selection';
  }

  if (!order || (order.returnRequestId && !returnRequest)) {
    return 'empty';
  }

  if (order.items.length > 0 && order.items.every(item => !item.isReturnable)) {
    return 'no-eligible-items';
  }

  if (order.isReturnNotSupported) {
    return 'return-not-supported';
  }

  if (returnPortalCountry?.toLowerCase() !== order.countryCode?.toLowerCase()) {
    return 'order-country-mismatch';
  }

  if (isConflictError(submitError)) {
    return 'return-already-created';
  }

  if (order.hasReturnShipment || submittedReturnRequest) {
    return submittedReturnRequest?.workflowType === 'external' ? 'external-redirect' : 'confirmation-redirect';
  }

  if (order.returnRequestId) {
    return returnRequest?.workflowType === 'external' ? 'update' : 'confirmation-redirect';
  }

  if (isReturnPeriodExpired(order, settings.returnPeriod)) {
    return 'expired';
  }

  if (returnCarrierServices.length < 2) {
    return isLite ? 'create-without-items' : 'create';
  }

  if (settings.isExternalRedirectEnabled) {
    return 'create';
  }

  return isLite ? 'next-without-items' : 'next';
}

export default useOverviewState;
