import { useHistory } from 'react-router';
import useWithBaseUrl from './useWithBaseUrl';
import { useCallback } from 'react';

function useRedirectTo(path: string) {
  const history = useHistory();
  const fullPath = useWithBaseUrl(path);

  return useCallback(() => history.push(fullPath), [history, fullPath]);
}

export default useRedirectTo;
