import i18next, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { ReturnPortalTranslations } from './types';

export function initI18next(translations: ReturnPortalTranslations = {}) {
  const resources = makeResourcesFromTranslations('common', translations);

  return i18next.use(initReactI18next).init({
    resources,
    fallbackLng: 'en',
    saveMissing: false,
    ns: ['common'],
    defaultNS: 'common',
    nsSeparator: false,
    keySeparator: false,
    react: {
      useSuspense: false,
    },
  });
}

export function makeResourcesFromTranslations(namespace: string, translations: ReturnPortalTranslations): Resource {
  const resource: Resource = {};

  const languages = Object.keys(translations);

  languages.forEach(lang => {
    resource[lang] = { [namespace]: translations[lang] };
  });

  return resource;
}
