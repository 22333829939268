import { Order } from './types';

const msInDay = 1000 * 60 * 60 * 24;

export function isReturnPeriodExpired(order: Order | null, returnPeriod: number = 0): boolean {
  if (order && returnPeriod > 0) {
    const expirationDate = calculateFinalExpirationDate(order, returnPeriod);
    const currentDate = new Date();

    return currentDate > expirationDate;
  }

  return false;
}

function calculateFinalExpirationDate(order: Order, returnPeriod: number): Date {
  const gracePeriod = 7;

  if (order.deliveryDate) {
    const deliveryDate = new Date(order.deliveryDate);
    return new Date(deliveryDate.getTime() + returnPeriod * msInDay);
  }

  const creationDate = new Date(order.creationDate);
  return new Date(creationDate.getTime() + (returnPeriod + gracePeriod) * msInDay);
}
