import { ReactElement, useContext, useEffect, useState } from 'react';
import { ReturnPortalContext } from '../contexts/ReturnPortalContext';
import i18next from 'i18next';
import { initI18next } from '../i18n';

function LanguageSetter(props: { children: ReactElement }) {
  const { language = 'en', translations } = useContext(ReturnPortalContext);
  const [isLanguageSet, setIsLanguageSet] = useState(false);

  useEffect(() => {
    setIsLanguageSet(false);

    // we need to wait for initialization before changing language
    initI18next(translations).then(() => {
      i18next.changeLanguage(language).then(() => {
        setIsLanguageSet(true);
      });
    });
  }, [language, translations]);

  return isLanguageSet ? props.children : null;
}

export default LanguageSetter;
