import React, { ReactNode } from 'react';
import PageLayout from './PageLayout';
import useChangeLanguage from '../../hooks/useChangeLanguage';

export interface Props {
  children?: ReactNode;
  loading?: boolean;
}

function PageLayoutWithLanguageSelector(props: Props) {
  const { changeLanguage, selectedLanguage } = useChangeLanguage();

  return <PageLayout {...props} selectedLanguage={selectedLanguage} onLanguageChange={changeLanguage} />;
}

export default React.memo(PageLayoutWithLanguageSelector);
