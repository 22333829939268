import React from 'react';

import DesktopLayout from './DesktopLayout';
import TabletLayout from './TabletLayout';
import MobileLayout from './MobileLayout';
import { LayoutWithActionProps } from './types';
import useReturnPortalSize from '../../hooks/useReturnPortalSize';
import LiteDesktopLayout from './LiteDesktopLayout';
import LiteMobileLayout from './LiteMobileLayout';
import LiteTabletLayout from './LiteTabletLayout';

const layouts = {
  s: MobileLayout,
  m: TabletLayout,
  l: (props: LayoutWithActionProps) => <DesktopLayout {...props} size="l" />,
  xl: (props: LayoutWithActionProps) => <DesktopLayout {...props} size="xl" />,
};

const liteLayouts = {
  s: LiteMobileLayout,
  m: LiteTabletLayout,
  l: (props: LayoutWithActionProps) => <LiteDesktopLayout {...props} size="l" />,
  xl: (props: LayoutWithActionProps) => <LiteDesktopLayout {...props} size="xl" />,
};

function LayoutWithAction(props: LayoutWithActionProps) {
  const { isLite } = props;
  const size = useReturnPortalSize();

  const Layout = isLite ? liteLayouts[size] : layouts[size];

  return <Layout {...props} />;
}

export default React.memo(LayoutWithAction);
