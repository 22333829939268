import React from 'react';
import { ReturnPortalSettings, ReturnPortalTranslations } from '../types';

export type ReturnPortalContextValue = {
  shopId?: number;
  isLite?: boolean;
  settings?: ReturnPortalSettings;
  staticUrl?: string;
  language?: string;
  countryCode?: string;
  hash?: string;
  hasBrandedUrl?: boolean;
  translations?: ReturnPortalTranslations;
  availableLanguages?: string[];
};

export const ReturnPortalContext = React.createContext<ReturnPortalContextValue>({});
