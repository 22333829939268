import styled from '@emotion/styled';

import colors from './colors';

const Heading1 = styled('h1')`
  color: ${colors.black};
  font-family: inherit;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin: 0;
`;

export default Heading1;
