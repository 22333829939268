import React from 'react';

import OrderInfo from './OrderInfo';
import { ItemToSubmit, Order } from '../../types';
import ViewHeading from '../page-layout/ViewHeading';
import LayoutWithAction from '../LayoutWithAction';
import ActionButton from './ActionButton';
import LiteOverviewContent from './LiteOverviewContent';

type Props = {
  order?: Order;
  buttonText: string;
  buttonName: string;
  isButtonLoading?: boolean;
  hasButtonError?: boolean;
  onButtonClick: (items: ItemToSubmit[]) => void;
};

function OrderWithoutItems(props: Props) {
  const { order, isButtonLoading, hasButtonError, buttonText, buttonName, onButtonClick } = props;

  const headingElement = <ViewHeading>overviewLite_heading</ViewHeading>;
  const infoElement = <OrderInfo order={order} orientation="column" />;
  const content = <LiteOverviewContent order={order} />;
  const button = (
    <ActionButton
      onClick={() => onButtonClick([])}
      isLoading={isButtonLoading}
      hasError={hasButtonError}
      name={buttonName}
    >
      {buttonText}
    </ActionButton>
  );

  return (
    <LayoutWithAction heading={headingElement} info={infoElement} content={content} button={button} isLite={true} />
  );
}

export default React.memo(OrderWithoutItems);
