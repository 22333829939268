import React from 'react';
import WindowSizeContext from '../contexts/WindowSizeContext';

export type ReturnPortalSize = 's' | 'm' | 'l' | 'xl';

function getSize(width: number): ReturnPortalSize {
  if (width > 1199) {
    return 'xl';
  } else if (width > 959) {
    return 'l';
  } else if (width > 599) {
    return 'm';
  } else return 's';
}

function useReturnPortalSize(): ReturnPortalSize {
  const { width } = React.useContext(WindowSizeContext);
  return getSize(width);
}

export default useReturnPortalSize;
