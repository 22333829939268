import { useEffect } from 'react';
import { useAsyncFn } from 'react-use';

import { useReturnPortalCountry } from './useReturnPortalCountry';
import { useSettings } from './useSettings';
import { getReturnCarrierServices } from '../api';
import { ReturnCarrierService } from '../types';

function useReturnCarrierServices(): [ReturnCarrierService[] | undefined, boolean, Error | undefined] {
  const country = useReturnPortalCountry();
  const { isExternalRedirectEnabled } = useSettings();

  const [state, fetchCarrierOptions] = useAsyncFn(() => getReturnCarrierServices(country), [country]);
  const { value, error, loading } = state;

  useEffect(() => {
    if (!isExternalRedirectEnabled) {
      fetchCarrierOptions();
    }
  }, [fetchCarrierOptions, isExternalRedirectEnabled]);

  return [value, loading, error];
}

export default useReturnCarrierServices;
