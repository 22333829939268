import React, { ChangeEvent, useCallback } from 'react';
import styled from '@emotion/styled';

import colors from '../common/colors';
import { ReactComponent as ArrowIcon } from '../../icons/dropdown.svg';

const Container = styled('div')`
  display: flex;
  position: relative;
`;

const ArrowContainer = styled('div')`
  position: absolute;
  top: 13px;
  right: 3px;
  pointer-events: none;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: ${colors.black};
`;

type StyleProps = {
  focusColor: string;
};

const StyledSelect = styled('select')<StyleProps>`
  width: 61px;
  height: 32px;
  padding: 4px 8px;
  box-sizing: border-box;
  border-width: 0;
  border-radius: 4px;
  background-color: ${colors.white};
  cursor: pointer;
  appearance: none;
  outline: none;
  font-weight: normal;
  letter-spacing: normal;
  color: #202124;
  font-size: 16px;
  font-family: font-family: inherited;

  &:focus {
    border-width: 2px;
    padding-left: 6px;
    border-color: ${props => props.focusColor};
  }

  &:hover {
    background: ${colors.lightGrey};
  }
`;

export interface Option {
  value: string;
  label: string;
}
interface Props {
  color: string;
  options: Option[];
  value?: string;
  onChange: (language: string) => void;
}

function LanguageSelector(props: Props) {
  const { color, options, onChange, value } = props;

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;
      onChange(value);
    },
    [onChange]
  );

  return (
    <Container>
      <StyledSelect
        aria-label="Select a language"
        name="languageSelect"
        value={value}
        onChange={onChangeHandler}
        focusColor={color}
      >
        {options.map(opt => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </StyledSelect>
      <ArrowContainer>
        <ArrowIcon />
      </ArrowContainer>
    </Container>
  );
}

export default React.memo(LanguageSelector);
