import { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';

import useOrder from './useOrder';
import useUnauthorizedRedirect from './useUnauthorizedRedirect';
import { createSubsequentReturnShipment } from '../api';
import { downloadPdf } from '../urlHelpers';
import { SubsequentReturnShipment } from '../types';

type Result = {
  isLabelAvailable: boolean;
  isLoading: boolean;
  error?: Error;
  createLabel: () => Promise<SubsequentReturnShipment>;
};

function useSubsequentReturn(): Result {
  const [availableLabelCount, setAvailableLabelCount] = useState(0);

  const [order] = useOrder();

  const [labelState, createLabel] = useAsyncFn(() => createSubsequentReturnShipment(), []);
  const { value: shipment, error, loading } = labelState;

  useUnauthorizedRedirect(error);

  useEffect(() => {
    if (order) {
      setAvailableLabelCount(order.returnShipmentsLeft);
    }
  }, [order]);

  useEffect(() => {
    if (shipment) {
      setAvailableLabelCount(count => count - 1);
      downloadPdf(shipment.labelUrl);
    }
  }, [shipment]);

  return {
    isLabelAvailable: availableLabelCount > 0,
    isLoading: loading,
    error,
    createLabel,
  };
}

export default useSubsequentReturn;
