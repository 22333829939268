import { useEffect } from 'react';
import WebFont from 'webfontloader';

import { isGoogleFont } from '../fontHelpers';

// IMPORTANT: useFontLoader is copypasted from `@sendwise/font-loader` package

function useFontLoader(font: string) {
  useEffect(() => {
    if (isGoogleFont(font)) {
      loadGoogleFonts([font]);
    }
  }, [font]);

  return null;
}

// Looks like webfontloader doesn't handle loading fonts that have been already loaded
// We use our own cache to check it
const loadedFonts = window.loadedFonts || (window.loadedFonts = []);

function loadGoogleFonts(fonts: string[]) {
  const fontsToLoad = fonts.filter(font => loadedFonts.indexOf(font) === -1);

  if (fontsToLoad.length === 0) {
    return false;
  }

  loadedFonts.push(...fontsToLoad);

  WebFont.load({
    google: {
      families: fontsToLoad.map(name => `${name}:300,400,500,600,700`),
    },
  });
}

export default useFontLoader;
