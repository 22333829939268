import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import Heading2 from '../common/Heading2';

const Container = styled('div')`
  width: 100%;
  margin-bottom: 24px;
`;

type Props = {
  children: string;
};

function ViewHeading(props: Props) {
  const { t } = useTranslation();

  return (
    <Container>
      <Heading2>{t(props.children)}</Heading2>
    </Container>
  );
}

export default React.memo(ViewHeading);
