import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

import Banner from './Banner';
import PageContainer from './PageContainer';
import { useSettings } from '../../hooks/useSettings';
import useReturnPortalSize from '../../hooks/useReturnPortalSize';
import PageFooter from './PageFooter';
import { isDesktop } from '../../sizeHelpers';
import Spinner from '../common/Spinner';
import PageHeader from './PageHeader';

const Body = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;

const Content = styled('div')`
  box-sizing: border-box;
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  &.l,
  &.xl {
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 40px;
    align-self: center;
  }

  &.l {
    width: 912px;
  }

  &.xl {
    width: 1152px;
  }

  margin-top: 32px;
`;

const LoaderContainer = styled('div')`
  height: 100%;
`;

export interface Props {
  children?: ReactNode;
  loading?: boolean;
  selectedLanguage?: string;
  onLanguageChange?: (language: string) => void;
}

function PageLayout(props: Props) {
  const { accentColor, bannerImageUrl, showBannerOnlyOnLogin } = useSettings();
  const size = useReturnPortalSize();

  const showBanner = !showBannerOnlyOnLogin && !isDesktop(size);

  return (
    <PageContainer>
      <Body>
        <PageHeader changeLanguage={props.onLanguageChange} selectedLanguage={props.selectedLanguage} />
        {showBanner && <Banner className={size} data-testid="banner" imageUrl={bannerImageUrl} />}
        <Content className={size}>
          {props.loading && (
            <LoaderContainer>
              <Spinner color={accentColor} />
            </LoaderContainer>
          )}
          {!props.loading && props.children}
        </Content>
      </Body>
      <PageFooter />
    </PageContainer>
  );
}

export default React.memo(PageLayout);
