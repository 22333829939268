import React from 'react';

import Column from '../common/layout/Column';
import NonReturnableProductItem from './NonReturnableProductItem';
import { GroupedReturnItem } from '../../types';

type Props = {
  items: GroupedReturnItem[];
};

function NonReturnableProductsList(props: Props) {
  const { items } = props;

  return (
    <Column spacing={24} data-testid="non-eligible-items">
      {items.map(item => (
        <NonReturnableProductItem key={item.productKey} {...item} />
      ))}
    </Column>
  );
}

export default React.memo(NonReturnableProductsList);
