import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import Heading3 from '../common/Heading3';

const StyledHeading3 = styled(Heading3)`
  margin-bottom: 16px;
`;

function ProductsListHeading(props: { children: string }) {
  const { t } = useTranslation();

  return <StyledHeading3>{t(props.children)}</StyledHeading3>;
}

export default ProductsListHeading;
