import React, { ReactNode } from 'react';

import DesktopViewLayout from './DesktopViewLayout';
import MobileViewLayout from './MobileViewLayout';
import TabletViewLayout from './TabletViewLayout';
import useReturnPortalSize from '../../hooks/useReturnPortalSize';
import { Order } from '../../types';
import Message, { Intent } from './Message';
import ViewHeading from '../page-layout/ViewHeading';
import OrderInfo from '../products-overview/OrderInfo';
import { isDesktop } from '../../sizeHelpers';

const layouts: { [key: string]: typeof MobileViewLayout } = {
  s: MobileViewLayout,
  m: TabletViewLayout,
  l: DesktopViewLayout,
  xl: DesktopViewLayout,
};

type Props = {
  heading: string;
  order?: Order;
  message?: string;
  intent?: Intent;
  children: ReactNode;
};

function PageView(props: Props) {
  const { heading, order, message, intent, children } = props;
  const size = useReturnPortalSize();
  const Layout = layouts[size];

  const headingElement = <ViewHeading>{heading}</ViewHeading>;
  const infoElement = <OrderInfo order={order} orientation={isDesktop(size) ? 'row' : 'column'} />;
  const messageElement = <Message intent={intent}>{message}</Message>;

  return (
    <Layout heading={headingElement} info={infoElement} message={messageElement}>
      {children}
    </Layout>
  );
}

export default PageView;
