import React from 'react';
import styled from '@emotion/styled';

import useReturnPortalSize from '../../hooks/useReturnPortalSize';
import ProductsListHeading from '../products-overview/ProductsListHeading';
import ProductsList from '../products-overview/ProductsList';
import { ReturnItem } from '../../types';
import { useGroupedItems } from '../../hooks/useGroupedItems';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  &.l,
  &.xl {
    margin-bottom: 32px;
  }
`;

type Props = {
  heading: string;
  items?: ReturnItem[];
};

function ConfirmationProductsList(props: Props) {
  const size = useReturnPortalSize();
  const { heading } = props;
  const items = useGroupedItems(props.items || []);

  return (
    <Container className={size}>
      <ProductsListHeading>{heading}</ProductsListHeading>
      <ProductsList items={items} />
    </Container>
  );
}

export default React.memo(ConfirmationProductsList);
