import React from 'react';

import { Order } from '../../types';
import styled from '@emotion/styled';
import Heading3 from '../common/Heading3';
import Text from '../common/Text';
import { useSettings } from '../../hooks/useSettings';
import { Trans, useTranslation } from 'react-i18next';
import Link from '../common/Link';

type Props = {
  order?: Order;
};

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledHeading = styled(Heading3)`
  margin-bottom: 8px;
  padding-top: 20px;
`;

const TextWithLineBreaks = styled(Text)`
  white-space: pre-wrap;
`;

function LiteOverviewContent(props: Props) {
  const { order } = props;
  const { t } = useTranslation();
  const { accentColor, customerServiceLink } = useSettings();

  return (
    <Container>
      <Text>{t('overviewLite_customerName')}</Text>
      <Text small secondary>
        {order?.customerName}
      </Text>
      <StyledHeading>{t('overviewLite_contentHeading')}</StyledHeading>
      <TextWithLineBreaks secondary>
        <Trans i18nKey={'overviewLite_contentMessage'}>
          <Link color={accentColor} href={customerServiceLink} />
        </Trans>
      </TextWithLineBreaks>
    </Container>
  );
}

export default React.memo(LiteOverviewContent);
