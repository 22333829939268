import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

interface Props {
  secondary?: boolean;
  small?: boolean;
}

const Text = styled('span')<Props>(
  {
    fontWeight: 'normal',
    letterSpacing: 'normal',
  },
  (props: Props) => ({
    color: props.secondary ? '#5f6369' : '#202124',
    fontSize: props.small ? 14 : 16,
    lineHeight: props.small ? '20px' : '24px',
  })
);

export function TextTrans(props: Props & { children?: string }) {
  const { children = '', ...textProps } = props;
  const { t } = useTranslation();
  return <Text {...textProps}>{t(children)}</Text>;
}

export default React.memo(Text);
