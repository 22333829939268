import styled from '@emotion/styled';
import colors from '../common/colors';

const HorizontalSeparator = styled('div')`
  box-sizing: border-box;
  border-top: 1px solid ${colors.lightGrey};
  margin-bottom: 20px;
`;

export default HorizontalSeparator;
