import { useCallback, useEffect, useMemo } from 'react';
import { Draft } from 'immer';
import { useImmerReducer } from 'use-immer';

import { ReturnItemState, OrderItem, OverviewReturnItem, ReturnRequestItem, ErrorsByProductKey } from '../types';
import { getGroupedItems, transformToReturnItems } from '../itemGroupingHelpers';

type State = {
  [productKey: string]: ReturnItemState;
};

type SelectItemAction = {
  type: 'SELECT_ITEM';
  productKey: string;
  selected: boolean;
};

type SelectItemReasonAction = {
  type: 'CHANGE_ITEM_REASON';
  productKey: string;
  reason: string;
};

type SelectItemQuantityAction = {
  type: 'CHANGE_ITEM_QUANTITY';
  productKey: string;
  quantity: number;
};

type SetItemsErrorAction = {
  type: 'SET_ITEMS_ERRORS';
  errors: ErrorsByProductKey;
};

type Action = SelectItemAction | SelectItemReasonAction | SelectItemQuantityAction | SetItemsErrorAction;

function returnItemsReducer(state: Draft<State>, action: Action): void | State {
  switch (action.type) {
    case 'SELECT_ITEM':
      return void (state[action.productKey] = {
        ...state[action.productKey],
        selected: action.selected,
      });
    case 'CHANGE_ITEM_REASON':
      return void (state[action.productKey] = {
        ...state[action.productKey],
        showReasonError: false,
        showQuantityError: false,
        selected: true,
        reason: action.reason,
      });
    case 'CHANGE_ITEM_QUANTITY':
      return void (state[action.productKey] = {
        ...state[action.productKey],
        quantity: action.quantity,
        showQuantityError: false,
      });
    case 'SET_ITEMS_ERRORS':
      return void Object.keys(action.errors).forEach((productKey: string) => {
        state[productKey].showReasonError = action.errors[productKey].reason;
        state[productKey].showQuantityError = action.errors[productKey].quantity;
      });
    default:
      return state;
  }
}

const initialState: State = {};

type Result = {
  returnItems: OverviewReturnItem[];
  selectItem: (productKey: string, selected: boolean) => void;
  changeItemReason: (productKey: string, reason: string) => void;
  changeItemQuantity: (productKey: string, quantity: number) => void;
  setItemsErrors: (errors: ErrorsByProductKey) => void;
};

function useReturnItems(orderItems: OrderItem[], returnRequestItems: ReturnRequestItem[] = []): Result {
  const [itemsState, dispatch] = useImmerReducer(returnItemsReducer, initialState);

  const selectItem = useCallback(
    (productKey: string, selected: boolean) => dispatch({ type: 'SELECT_ITEM', productKey, selected }),
    [dispatch]
  );

  const changeItemReason = useCallback(
    (productKey: string, reason: string) => dispatch({ type: 'CHANGE_ITEM_REASON', productKey, reason }),
    [dispatch]
  );

  const changeItemQuantity = useCallback(
    (productKey: string, quantity: number) => dispatch({ type: 'CHANGE_ITEM_QUANTITY', productKey, quantity }),
    [dispatch]
  );

  const setItemsErrors = useCallback((errors: ErrorsByProductKey) => dispatch({ type: 'SET_ITEMS_ERRORS', errors }), [
    dispatch,
  ]);

  // items selection for external return request re-entry
  useEffect(() => {
    const groupedItems = getGroupedItems(returnRequestItems);

    groupedItems.forEach(item => {
      const { productKey, totalQuantity } = item;
      const reason = findReasonForProductKey(productKey, returnRequestItems);

      changeItemQuantity(productKey, totalQuantity);

      if (reason) {
        changeItemReason(productKey, reason);
      }
    });
  }, [returnRequestItems, changeItemQuantity, changeItemReason]);

  const items = useMemo(() => getGroupedItems(transformToReturnItems(orderItems)), [orderItems]);

  const returnItems = useMemo(
    () =>
      items.map(item => ({
        ...item,
        ...itemsState[item.productKey],
      })),
    [itemsState, items]
  );

  return {
    returnItems,
    selectItem,
    changeItemReason,
    setItemsErrors,
    changeItemQuantity,
  };
}

function findReasonForProductKey(productKey: string, returnRequestItems: ReturnRequestItem[]): string | undefined {
  const foundItem = returnRequestItems.find(item => item.productKey === productKey);
  return foundItem?.reason;
}

export default useReturnItems;
