import React from 'react';
import styled from '@emotion/styled';

import ContactUs from '../invalid-returns/ContactUs';

type Props = {
  children?: string;
};

const Container = styled('div')`
  width: 100%;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 32px;
  line-height: 24px;
`;

function ConfirmationContactUs(props: Props) {
  const { children = 'confirmation_contactUs' } = props;

  return (
    <Container>
      <ContactUs>{children}</ContactUs>
    </Container>
  );
}

export default React.memo(ConfirmationContactUs);
