import React from 'react';

import ContactUs from './ContactUs';
import { Order } from '../../types';
import PageView from '../PageView';
import useReturnPortalType from '../../hooks/useReturnPortalType';

interface Props {
  order?: Order;
  message: string;
  children?: React.ReactNode;
}

function ErrorView(props: Props) {
  const { order, message, children } = props;
  const { isLite } = useReturnPortalType();

  const heading = isLite ? 'errorLite_heading' : 'error_heading';

  return (
    <PageView heading={heading} order={order} message={message} intent="info">
      <ContactUs>error_contactUs</ContactUs>
      {children}
    </PageView>
  );
}

export default React.memo(ErrorView);
