import React from 'react';
import { useTranslation } from 'react-i18next';

import ErrorView from './ErrorView';
import { Order } from '../../types';

type Props = {
  order?: Order;
};

function ReturnAlreadyCreated(props: Props) {
  const { order } = props;
  const { t } = useTranslation();

  return <ErrorView order={order} message={t('returnAlreadyCreatedMessage')} />;
}

export default ReturnAlreadyCreated;
