import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSettings } from '../../hooks/useSettings';
import Button from '../common/Button';

type Props = {
  children: string;
  name: string;
  hasError?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
};

function ActionButton(props: Props) {
  const { children, name, isLoading, isDisabled, hasError, onClick } = props;
  const { accentColor } = useSettings();
  const { t } = useTranslation();

  return (
    <Button
      name={name}
      fullWidth
      color={accentColor}
      disabled={isDisabled}
      onClick={onClick}
      isLoading={isLoading}
      error={hasError}
    >
      {hasError ? t('tryAgainButton') : t(children)}
    </Button>
  );
}

export default React.memo(ActionButton);
