import React from 'react';
import styled from '@emotion/styled';

import useReturnPortalSize from '../../hooks/useReturnPortalSize';
import HorizontalPadding from '../HorizontalPadding';

const Container = styled(HorizontalPadding)`
  flex-shrink: 0;

  &.l,
  &.xl {
    padding: 0 56px;
  }
`;

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

function PagePadding(props: Props) {
  const size = useReturnPortalSize();
  return <Container className={size}>{props.children}</Container>;
}

export default React.memo(PagePadding);
