import React from 'react';

import ErrorPageContainer from './ErrorPageContainer';

type Props = {
  font?: string;
  color?: string;
  children?: string;
};

function ErrorPage(props: Props) {
  const { font, color, children } = props;

  return (
    <ErrorPageContainer font={font} color={color} heading={children}>
      The page you are looking for is currently unavailable. Please come back later.
    </ErrorPageContainer>
  );
}

export default React.memo(ErrorPage);
