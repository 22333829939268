import { ErrorsByProductKey, ItemErrors, OverviewReturnItem } from '../../types';

export function validateItems(items: OverviewReturnItem[]): ErrorsByProductKey {
  return items.reduce<ErrorsByProductKey>((acc, item) => ({ ...acc, [item.productKey]: validateItem(item) }), {});
}

function validateItem(item: OverviewReturnItem): ItemErrors {
  return {
    quantity: item.totalQuantity > 1 && !item.quantity,
    reason: !item.reason && item.selected,
  };
}
