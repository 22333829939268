import { useContext, useMemo } from 'react';
import { ReturnPortalContext } from '../contexts/ReturnPortalContext';
import applyDefaults from '../applyDefaultSettings';
import { ReturnPortalSettings } from '../types';

export type ReturnPortalSettingsWithDefaults = ReturnPortalSettings & {
  logoImageUrl: string;
  bannerImageUrl: string;
  accentColor: string;
  font: string;
  isExternalRedirectEnabled: boolean;
};

export function useSettings(): ReturnPortalSettingsWithDefaults {
  const { settings, staticUrl } = useContext(ReturnPortalContext);

  return useMemo(() => applyDefaults(settings, staticUrl), [settings, staticUrl]);
}
