import React from 'react';
import styled from '@emotion/styled';
import { Trans } from 'react-i18next';

import { ReactComponent as ErrorIcon } from '../../icons/error.svg';
import { TextTrans } from '../common/Text';
import colors from '../common/colors';

type ContainerProps = {
  font: string;
};

const Container = styled('div')<ContainerProps>`
  font-family: ${props => props.font};
  max-width: 520px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  margin: 0 auto;
`;

const IconStand = styled('div')`
  background-color: ${props => props.color};
  border-radius: 50%;
  width: 56px;
  height: 56px;
  fill: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
  flex-shrink: 0;
`;

const ErrorHeading = styled('h2')`
  color: ${colors.black};
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin: 0;
`;

const Paragraph = styled('p')`
  margin: 10px 0;
  text-align: center;
`;

type Props = {
  font?: string;
  color?: string;
  heading?: string;
  children: string;
};

function ErrorPageContainer(props: Props) {
  const { font = 'Roboto, sans-serif', color = colors.blue, heading, children } = props;

  return (
    <Container font={font} data-testid="error-container">
      <IconStand color={color} data-testid="error-icon">
        <ErrorIcon />
      </IconStand>
      <ErrorHeading>{heading || <Trans>Something went wrong</Trans>}</ErrorHeading>
      <Paragraph>
        <TextTrans secondary small>
          {children}
        </TextTrans>
      </Paragraph>
    </Container>
  );
}

export default React.memo(ErrorPageContainer);
