import { useParams } from 'react-router';

export default function useWithBaseUrl(path: string) {
  const { hash, route } = useParams();

  if (hash) {
    return `/${hash}${path}`;
  }

  if (route) {
    return `/${route}/pages${path}`;
  }

  return `/pages${path}`;
}
