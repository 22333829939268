import styled from '@emotion/styled';

/**
 * @deprecated use DesktopPadding, MobilePadding and TabletPadding
 */
export default styled('div')`
  padding-left: 20px;
  padding-right: 20px;

  &.m {
    padding-left: 40px;
    padding-right: 40px;
  }

  &.l,
  &.xl {
    padding-left: 32px;
    padding-right: 32px;
  }
`;
