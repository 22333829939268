import React from 'react';
import { useTranslation } from 'react-i18next';

import ErrorView from './ErrorView';
import NonReturnableProducts from '../products-overview/NonReturnableProducts';
import { Order } from '../../types';
import { transformToReturnItems } from '../../itemGroupingHelpers';
import { useGroupedItems } from '../../hooks/useGroupedItems';

type Props = {
  order?: Order;
};

function NoEligibleItems(props: Props) {
  const { order } = props;
  const { t } = useTranslation();
  const returnItems = transformToReturnItems(order?.items || []);
  const groupedItems = useGroupedItems(returnItems);

  return (
    <ErrorView order={order} message={t('noItemEligibleMessage')}>
      <NonReturnableProducts items={groupedItems} />
    </ErrorView>
  );
}

export default React.memo(NoEligibleItems);
