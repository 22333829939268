import React from 'react';
import styled from '@emotion/styled';

import { LayoutWithActionProps } from './types';
import DesktopPadding from '../paddings/DesktopPadding';
import colors from '../common/colors';

const BodyContainer = styled('div')`
  width: 100%;
  background-color: ${colors.white};
  box-sizing: border-box;
  padding: 32px 0;
  margin-right: 24px;

  &.xl {
    width: 100%
`;

const ButtonContainer = styled('div')`
  box-sizing: border-box;
  background-color: ${colors.white};
  padding: 0 32px 32px 32px;
  height: fit-content;
  flex: 1 0 auto;
  width: 100%;
  & :first-of-type {
    width: 287px;
  }
`;

const Content = styled('div')`
  display: flex;
  flex-direction: column;
`;

const Container = styled('div')`
  width: 100%;
`;

type DesktopSize = 'l' | 'xl';
type DesktopLayoutProps = LayoutWithActionProps & { size: DesktopSize };

function LiteDesktopLayout(props: DesktopLayoutProps) {
  const { size, heading, info, content, button } = props;

  return (
    <Container>
      {heading}
      <Content>
        <BodyContainer className={size}>
          <DesktopPadding>
            {info}
            {content}
          </DesktopPadding>
        </BodyContainer>
        <ButtonContainer>{button}</ButtonContainer>
      </Content>
    </Container>
  );
}

export default React.memo(LiteDesktopLayout);
