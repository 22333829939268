import { useEffect } from 'react';
import { useAsyncFn } from 'react-use';

import useUnauthorizedRedirect from './useUnauthorizedRedirect';
import { getOrder } from '../api';
import { Order } from '../types';

function useOrder(): [Order | undefined, boolean, Error | undefined] {
  const [state, fetchOrder] = useAsyncFn(() => getOrder(), []);
  const { value, error, loading } = state;

  useUnauthorizedRedirect(error);

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder]);

  return [value, loading, error];
}

export default useOrder;
