import React from 'react';
import styled from '@emotion/styled';

import ProductImage from './ProductImage';
import ProductInfo from './ProductInfo';

const Container = styled('div')`
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
`;

export type Item = {
  name: string;
  size: string;
  price: number;
  currency: string;
  productImageUrl?: string;
  totalQuantity: number;
  isQuantityHidden?: boolean;
};

type Props = Item;

function ProductItem(props: Props) {
  const { productImageUrl, name, size, price, currency, totalQuantity, isQuantityHidden } = props;

  return (
    <Container data-testid="product-item">
      <ProductImage url={productImageUrl} />
      <ProductInfo
        name={name}
        size={size}
        price={price}
        currency={currency}
        quantity={totalQuantity}
        isQuantityHidden={isQuantityHidden}
      />
    </Container>
  );
}

export default React.memo(ProductItem);
