import React, { ChangeEvent, useCallback } from 'react';
import styled from '@emotion/styled';

import colors from './colors';
import { ReactComponent as CheckIcon } from '../../icons/check.svg';

export interface Props {
  name?: string;
  checked?: boolean;
  onChange?: (checked: boolean, name?: string) => void;
  color: string;
}

const Input = styled('input')`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
`;

const Label = styled('label')`
  position: relative; // needed because some browsers try to scroll to input position after click
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  min-width: 48px;
  min-height: 48px;
`;

type IndicatorProps = {
  color: string;
};

const Indicator = styled('div')<IndicatorProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  background-color: ${colors.white};
  border-radius: 3px;
  border: 1px solid ${colors.lightGrey};

  input:checked + & {
    border-color: ${props => props.color};
    fill: ${props => props.color};
  }

  input:focus + & {
    border-color: ${props => props.color};
    fill: ${props => props.color};
    border-width: 2px;
    outline: none;
  }
`;

function Checkbox(props: Props) {
  const { name, checked = false, onChange = () => {} } = props;

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.checked, name);
    },
    [onChange, name]
  );

  return (
    <Label>
      <Input type="checkbox" name={name} checked={checked} onChange={onChangeHandler} />
      <Indicator color={colors.blue}>{checked && <CheckIcon />}</Indicator>
    </Label>
  );
}

export default React.memo(Checkbox);
