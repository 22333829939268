import React from 'react';

import ErrorPageContainer from './ErrorPageContainer';
import PageLayoutWithLanguageSelector from '../page-layout/PageLayoutWithLanguageSelector';

type Props = {
  errorCode?: number;
};

function ErrorPageWithLayout(props: Props) {
  const { errorCode } = props;

  const heading = errorCode ? `${errorCode}: Internal server error` : 'Unknown error';

  return (
    <PageLayoutWithLanguageSelector>
      <ErrorPageContainer heading={heading}>
        Oops! Something went wrong on our end. Please try again later.
      </ErrorPageContainer>
    </PageLayoutWithLanguageSelector>
  );
}

export default React.memo(ErrorPageWithLayout);
