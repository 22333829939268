import React from 'react';
import styled from '@emotion/styled';

import { ReturnConfirmationViewProps } from './types';
import { useSettings } from '../../hooks/useSettings';
import Heading3 from '../common/Heading3';
import Text from '../common/Text';
import PageView from '../PageView';
import { useGeneralConfirmationMessage } from './useConfirmationMessage';
import ConfirmationProductsList from './ConfirmationProductsList';
import ConfirmationContactUs from '../confirmation/ConfirmationContactUs';

const StyledHeading = styled(Heading3)`
  display: block;
  margin-bottom: 8px;
`;

function KeepItemsConfirmationView(props: ReturnConfirmationViewProps) {
  const { keepItemsConfirmationTitle, keepItemsConfirmationBody } = useSettings();
  const { returnRequest, returnState } = props;
  const message = useGeneralConfirmationMessage('confirmationKeepItems_message', returnState, returnRequest);

  return (
    <PageView heading="confirmationKeepItems_heading" message={message} intent="success">
      <ConfirmationContactUs>confirmationKeepItems_contactUs</ConfirmationContactUs>
      <StyledHeading>{keepItemsConfirmationTitle}</StyledHeading>
      <Text secondary>{keepItemsConfirmationBody}</Text>
      <ConfirmationProductsList heading="confirmationKeepItems_productsListHeading" items={returnRequest.items} />
    </PageView>
  );
}

export default React.memo(KeepItemsConfirmationView);
