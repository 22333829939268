import React from 'react';
import styled from '@emotion/styled';

import colors from '../common/colors';

import { ReactComponent as SuccessIcon } from '../../icons/checkmark.svg';
import { ReactComponent as InfoIcon } from '../../icons/info.svg';
import { ReactComponent as ErrorIcon } from '../../icons/error.svg';
import useReturnPortalSize from '../../hooks/useReturnPortalSize';

const icons = {
  error: ErrorIcon,
  success: SuccessIcon,
  info: InfoIcon,
};

const iconColors = {
  error: colors.red,
  success: colors.green,
  info: colors.black,
};

const backgroundColors = {
  error: colors.lightRed,
  success: colors.lightGreen,
  info: colors.smoke,
};

export type Intent = 'error' | 'success' | 'info';

type Props = {
  intent?: Intent;
  children?: string;
};

type ContainerProps = {
  intent: Intent;
};

const Container = styled('div')<ContainerProps>`
  display: flex;
  align-items: center;
  background-color: ${props => backgroundColors[props.intent]};
  padding: 16px 20px;
  box-sizing: border-box;
  line-height: 24px;
`;

const IconContainer = styled('div')<ContainerProps>`
  flex-shrink: 0;
  line-height: 0; // because it's flex container thus display: block is forced
  margin-right: 8px;
  align-self: self-start;
  fill: ${props => iconColors[props.intent]};
`;

function Message(props: Props) {
  const { intent = 'info', children } = props;

  const Icon = icons[intent] || (() => {});

  const size = useReturnPortalSize();

  return (
    <Container className={size} intent={intent}>
      <IconContainer intent={intent}>
        <Icon />
      </IconContainer>
      {children}
    </Container>
  );
}

export default React.memo(Message);
