import React, { Ref, useMemo } from 'react';
import styled from '@emotion/styled';

import Checkbox from '../common/Checkbox';
import ProductItem from './ProductItem';
import Select from '../common/Select';
import { OverviewReturnItem } from '../../types';
import { useSettings } from '../../hooks/useSettings';
import useReturnPortalSize from '../../hooks/useReturnPortalSize';
import { useTranslation } from 'react-i18next';

interface Props {
  item: OverviewReturnItem;
  onCheck?: (checked: boolean) => void;
  onReasonChange?: (reason: string) => void;
  onQuantityChange?: (quantity: string) => void;
}

const ItemWithCheckbox = styled('div')`
  width: 100%;
  display: flex;
`;

const Item = styled('div')`
  margin-left: 6px;
  flex-grow: 1;
  min-width: 0;
`;

const Container = styled('div')`
  min-width: 0;
  display: flex;
  min-height: 112px;

  &.s {
    display: inherit;
  }
`;

const QuantityContainer = styled('div')`
  margin-bottom: 8px;
`;

const SelectContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-left: 52px;
  margin-top: 12px;

  &.m,
  &.l,
  &.xl {
    flex-shrink: 0;
    box-sizing: content-box;
    padding-left: 24px;
    margin-top: 0;
    margin-bottom: auto;
  }

  &.m {
    width: 192px;
  }
  &.l {
    width: 200px;
  }
  &.xl {
    width: 264px;
  }
`;

const SelectableProductItem = React.forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  const {
    item: { selected, reason, showReasonError, quantity, totalQuantity, showQuantityError },
    onCheck = () => {},
    onReasonChange = () => {},
    onQuantityChange = () => {},
  } = props;

  const { font, accentColor, reasonOptions = [] } = useSettings();
  const { t } = useTranslation();

  const reasonErrorText = showReasonError ? t('overview_reasonError') : null;
  const quantityErrorText = showQuantityError ? t('overview_quantityError') : null;

  const placeholder = {
    value: '',
    label: t('overview_reasonSelectPlaceholder'),
  };

  const size = useReturnPortalSize();

  const quantityPlaceholder = {
    value: '',
    label: t('overview_quantitySelectPlaceholder'),
  };

  const translatedReasonOptions = useMemo(
    () => reasonOptions.map(option => ({ value: option.value, label: t(option.value) })),
    [reasonOptions, t]
  );

  const quantityOptions = useMemo(
    () =>
      Array.from({ length: totalQuantity }).map((_, i) => ({
        value: i + 1,
        label: (i + 1).toString(),
      })),
    [totalQuantity]
  );

  return (
    <Container className={size} ref={ref} data-testid="selectable-product-item">
      <ItemWithCheckbox>
        <Checkbox name="productItemSelected" checked={selected} onChange={onCheck} color={accentColor} />
        <Item>
          <ProductItem {...props.item} isQuantityHidden />
        </Item>
      </ItemWithCheckbox>
      <SelectContainer className={size}>
        {totalQuantity > 1 && (
          <QuantityContainer>
            <Select
              name="productQuantity"
              value={quantity || ''}
              options={[quantityPlaceholder, ...quantityOptions]}
              onChange={onQuantityChange}
              error={quantityErrorText}
              color={accentColor}
              font={font}
            />
          </QuantityContainer>
        )}
        <Select
          name="productItemReason"
          value={reason || ''}
          options={[placeholder, ...translatedReasonOptions]}
          onChange={onReasonChange}
          error={reasonErrorText}
          color={accentColor}
          font={font}
        />
      </SelectContainer>
    </Container>
  );
});

export default React.memo(SelectableProductItem);
