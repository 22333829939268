export default {
  red: '#ce3535',
  lightRed: '#f2e2e2',
  green: '#3bb273',
  lightGreen: '#eaf3ec',
  blue: '#1a73e8',
  white: '#ffffff',
  black: '#202124',
  lightGrey: '#dbdce0',
  grey: '#9e9e9e',
  smoke: '#f1f1f1',
  snow: '#f8f8f8',
};
