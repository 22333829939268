import { useContext } from 'react';
import { ReturnPortalContext } from '../contexts/ReturnPortalContext';

type Result = {
  isLite: boolean;
};

function useReturnPortalType(): Result {
  const { isLite } = useContext(ReturnPortalContext);
  return { isLite: !!isLite };
}

export default useReturnPortalType;
