import React from 'react';
import styled from '@emotion/styled';

import { ViewLayoutProps } from './types';
import MobilePadding from '../paddings/MobilePadding';

const MessageContainer = styled('div')`
  margin: 0 -20px; // message does not have left and right paddings from the parent container
`;

function MobileViewLayout(props: ViewLayoutProps) {
  const { heading, info, message, children } = props;

  return (
    <MobilePadding>
      {heading}
      {info}
      {message && <MessageContainer>{message}</MessageContainer>}
      {children}
    </MobilePadding>
  );
}

export default MobileViewLayout;
