import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { darken } from 'polished';

import colors from './colors';
import Spinner from './Spinner';

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  error?: boolean;
  color: string;
  isLoading?: boolean;
  fullWidth?: boolean;
  children: ReactNode;
}

interface StyleProps {
  backgroundColor: string;
  isLoading?: boolean;
  fullWidth?: boolean;
  name?: string;
}

const StyledButton = styled('button')<StyleProps>`
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  max-width: 100%;
  box-sizing: border-box;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-radius: 4px;
  padding: 0 24px;
  outline: none;
  background-color: ${props => props.backgroundColor};
  border: none;
  color: ${colors.white};
  cursor: pointer;
  font-family: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  position: ${props => (props.isLoading ? 'relative' : 'static')};

  &:hover {
    background: ${props => darken(0.1, props.backgroundColor)};
  }

  &:active {
    background: ${props => darken(0.2, props.backgroundColor)};
  }

  &:focus {
    background: ${props => darken(0.1, props.backgroundColor)};
    border: 2px solid ${props => darken(0.2, props.backgroundColor)};
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

type TextProps = {
  isLoading?: boolean;
};

const Text = styled('span')<TextProps>`
  text-overflow: ellipsis;
  overflow: hidden;
  visibility: ${props => (props.isLoading ? 'hidden' : 'visible')};
`;

const SpinnerContainer = styled('div')`
  position: absolute;
  width: 24px;
  height: 24px;
`;

function Button(props: Props) {
  const { name, children, onClick, disabled, type, error, isLoading, fullWidth, color } = props;
  const bgColor = error ? colors.red : color;

  return (
    <StyledButton
      name={name}
      onClick={onClick}
      disabled={disabled || isLoading}
      isLoading={isLoading}
      type={type}
      fullWidth={fullWidth}
      backgroundColor={bgColor || colors.blue}
    >
      <Text isLoading={isLoading}>{children}</Text>
      {isLoading && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
    </StyledButton>
  );
}

export default React.memo(Button);
