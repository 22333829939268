import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

import { useSettings } from '../../hooks/useSettings';
import useFontLoader from '../../hooks/useFontLoader';
import useReturnPortalSize from '../../hooks/useReturnPortalSize';
import { getFontFamilyWithFallback } from '../../fontHelpers';
import colors from '../common/colors';

type Layout = 'row' | 'column';

interface ContainerProps {
  font?: string;
  layout: Layout;
}

const Container = styled('div')<ContainerProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${props => props.layout};
  overflow-y: auto;
  font-family: ${props => props.font};
  background-color: ${colors.smoke};
  &.s,
  &.m {
    background-color: ${colors.white};
  }
`;

export interface Props {
  layout?: Layout;
  children?: ReactNode;
}

function PageContainer(props: Props) {
  const { layout = 'column', children } = props;
  const { font } = useSettings();
  const size = useReturnPortalSize();

  useFontLoader(font);

  return (
    <Container className={size} layout={layout} font={getFontFamilyWithFallback(font)}>
      {children}
    </Container>
  );
}

export default React.memo(PageContainer);
