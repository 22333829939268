import React from 'react';
import styled from '@emotion/styled';

import Footer from './Footer';
import PagePadding from './PagePadding';
import { useSettings } from '../../hooks/useSettings';

const Container = styled('div')`
  margin-top: auto;
  padding-top: 40px;
`;

function PageFooter() {
  const { isFooterEnabled } = useSettings();

  return (
    <Container data-testid="footer">
      <PagePadding>{isFooterEnabled && <Footer />}</PagePadding>
    </Container>
  );
}

export default React.memo(PageFooter);
