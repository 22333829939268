import React from 'react';

import AppRouter from './AppRouter';
import SettingsLoader from './SettingsLoader';
import ErrorBoundary from './error/ErrorBoundary';
import LanguageSetter from './LanguageSetter';
import { useWindowSize } from 'react-use';
import WindowSizeContext from '../contexts/WindowSizeContext';

function App() {
  const windowSize = useWindowSize();
  const { hostname, pathname } = window.location;

  return (
    <WindowSizeContext.Provider value={windowSize}>
      <SettingsLoader hostname={hostname} pathname={pathname}>
        <ErrorBoundary>
          <LanguageSetter>
            <AppRouter />
          </LanguageSetter>
        </ErrorBoundary>
      </SettingsLoader>
    </WindowSizeContext.Provider>
  );
}

export default React.memo(App);
