import React from 'react';
import styled from '@emotion/styled';

import colors from '../common/colors';
import { LayoutWithActionProps } from './types';
import TabletPadding from '../paddings/TabletPadding';
import StickyButtonContainer from './StickyButtonContainer';

const Container = styled('div')`
  width: 100%;
`;

const BodyContainer = styled(TabletPadding)`
  background-color: ${colors.white};
  box-sizing: border-box;
  padding-bottom: 20px;
`;

function TabletLayout(props: LayoutWithActionProps) {
  const { heading, info, content, button } = props;

  return (
    <Container>
      <BodyContainer>
        {heading}
        {info}
        {content}
      </BodyContainer>
      <StickyButtonContainer>
        <TabletPadding>{button}</TabletPadding>
      </StickyButtonContainer>
    </Container>
  );
}

export default React.memo(TabletLayout);
