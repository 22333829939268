import React, { useCallback, useState } from 'react';

import CarrierOptions from './CarrierOptions';
import SubmitReturnButton from './SubmitReturnButton';
import { useSettings } from '../../hooks/useSettings';
import { Order, ReturnCarrierService } from '../../types';
import { SubmitState } from '../../hooks/useReturnRequestActions';
import useReturnPortalSize from '../../hooks/useReturnPortalSize';
import LayoutWithAction from '../LayoutWithAction';
import ViewHeading from '../page-layout/ViewHeading';
import OrderInfo from '../products-overview/OrderInfo';
import { isDesktop } from '../../sizeHelpers';

export type Props = {
  order?: Order;
  submitState: SubmitState;
  onSubmit: (carrierService: ReturnCarrierService) => void;
  returnCarrierServices?: ReturnCarrierService[];
};

function CarrierSelection(props: Props) {
  const { order, returnCarrierServices, submitState, onSubmit } = props;

  const [selectedServiceId, setSelectedServiceId] = useState<string>();
  const { accentColor } = useSettings();
  const size = useReturnPortalSize();

  const onSubmitButtonClick = useCallback(() => {
    const carrierService = returnCarrierServices?.find(service => service.id === selectedServiceId);

    if (carrierService) {
      onSubmit(carrierService);
    }
  }, [selectedServiceId, returnCarrierServices, onSubmit]);

  const headingElement = <ViewHeading>selectCarrier_heading</ViewHeading>;
  const infoElement = <OrderInfo order={order} orientation={isDesktop(size) ? 'row' : 'column'} />;
  const carrierOptionsElement = (
    <CarrierOptions
      options={returnCarrierServices}
      color={accentColor}
      selectedServiceId={selectedServiceId}
      onClick={setSelectedServiceId}
    />
  );
  const submitReturnElement = (
    <SubmitReturnButton disabled={!selectedServiceId} submitState={submitState} onClick={onSubmitButtonClick} />
  );

  return (
    <LayoutWithAction
      heading={headingElement}
      info={infoElement}
      content={carrierOptionsElement}
      button={submitReturnElement}
    />
  );
}

export default React.memo(CarrierSelection);
