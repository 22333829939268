import React from 'react';
import { Redirect } from 'react-router';

import { LocationState, ReturnRequest } from '../../types';
import useWithBaseUrl from '../../hooks/useWithBaseUrl';
import { SubmitState } from '../../hooks/useReturnRequestActions';

type Props = {
  returnRequest?: ReturnRequest;
  submitState: SubmitState;
};

function ConfirmationRedirect(props: Props) {
  const {
    returnRequest,
    submitState: { submittedReturnRequest },
  } = props;
  const confirmationUrl = useWithBaseUrl('/return-confirmation');
  const returnRequestId = submittedReturnRequest?.id || returnRequest?.id;

  if (!returnRequestId) {
    return null;
  }

  const state: LocationState = { returnRequestId, returnState: submittedReturnRequest ? 'submitted' : 'exists' };

  return <Redirect to={{ pathname: confirmationUrl, state }} push={false} />;
}

export default React.memo(ConfirmationRedirect);
