import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import useWithBaseUrl from '../../hooks/useWithBaseUrl';
import useShopId from '../../hooks/useShopId';
import useAuth from '../../hooks/useAuth';

function DeepLinkLogin() {
  const overviewUrl = useWithBaseUrl('/return-overview');
  const loginUrl = useWithBaseUrl('/login');
  const shopId = useShopId();
  const { login } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const values = queryString.parse(location.search, { decode: true });
  const orderId = String(values.order || '');
  const email = String(values.email || '');

  useEffect(() => {
    if (!orderId || !email) {
      history.push(loginUrl);
    } else {
      login({ orderId, email, shopId }).then((success: boolean) => {
        if (success) {
          history.push(overviewUrl);
        } else {
          history.push(loginUrl);
        }
      });
    }
  }, [email, history, login, loginUrl, orderId, overviewUrl, shopId]);

  return <div>Logging in...</div>;
}

export default React.memo(DeepLinkLogin);
