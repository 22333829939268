import { ReturnPortalSettings } from './types';
import { ReturnPortalSettingsWithDefaults } from './hooks/useSettings';
import colors from './components/common/colors';

function applyDefaults(settings?: ReturnPortalSettings, staticUrl: string = '/'): ReturnPortalSettingsWithDefaults {
  const defaults = {
    logoImageUrl: `${staticUrl}default-logo.svg`,
    bannerImageUrl: `${staticUrl}header-image.jpg`,
    bannerDesktopImageUrl: `${staticUrl}header-image-desktop.jpg`,
  };

  return {
    ...defaults,
    ...settings,
    logoImageUrl: settings?.logoImageUrl || defaults.logoImageUrl,
    bannerImageUrl: settings?.bannerImageUrl || defaults.bannerImageUrl,
    bannerDesktopImageUrl: settings?.bannerDesktopImageUrl || defaults.bannerDesktopImageUrl,
    accentColor: settings?.accentColor || colors.blue,
    font: settings?.font || 'Roboto',
    isExternalRedirectEnabled: settings?.isExternalRedirectEnabled || false,
  };
}

export default applyDefaults;
