import React from 'react';
import styled from '@emotion/styled';

import { Trans, useTranslation } from 'react-i18next';

import Link from '../common/Link';

import { useSettings } from '../../hooks/useSettings';

type Props = {
  children: string;
};

const Container = styled('div')`
  width: 100%;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 32px;
  line-height: 24px;
`;

function ContactUs(props: Props) {
  const { children } = props;
  const { accentColor, customerServiceLink } = useSettings();

  useTranslation();

  return (
    <Container>
      <Trans i18nKey={children}>
        <Link color={accentColor} href={customerServiceLink} />
      </Trans>
    </Container>
  );
}

export default React.memo(ContactUs);
