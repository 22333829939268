import React from 'react';

import { ReturnConfirmationViewProps } from './types';
import PageView from '../PageView';
import ConfirmationContactUs from './ConfirmationContactUs';
import { useGeneralConfirmationMessage } from './useConfirmationMessage';
import ConfirmationProductsList from './ConfirmationProductsList';

function ExternalConfirmationView(props: ReturnConfirmationViewProps) {
  const { returnRequest, returnState } = props;
  const message = useGeneralConfirmationMessage('confirmationExternal_message', returnState, returnRequest);

  return (
    <PageView heading="confirmationExternal_heading" message={message} intent="success">
      <ConfirmationContactUs>confirmationExternal_contactUs</ConfirmationContactUs>
      <ConfirmationProductsList heading="confirmationExternal_productsListHeading" items={returnRequest.items} />
    </PageView>
  );
}

export default React.memo(ExternalConfirmationView);
