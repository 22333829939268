import { GroupedReturnItem, ItemToSubmit, OrderItem, OverviewReturnItem, ReturnItem } from './types';

export function getGroupedItems(items: ReturnItem[]): GroupedReturnItem[] {
  return items.reduce<GroupedReturnItem[]>((acc, item) => {
    const itemInAcc = acc.find(i => i.productKey === item.productKey && i.isReturnable === item.isReturnable);

    if (itemInAcc) {
      itemInAcc.totalQuantity++;
    } else {
      acc.push({ ...item, totalQuantity: 1 });
    }

    return acc;
  }, []);
}

export function getUngroupedItems(orderItems: OrderItem[], selectedItems: OverviewReturnItem[]): ItemToSubmit[] {
  const items = [];
  const returnItems = transformToReturnItems(orderItems);

  for (let i = 0; i < selectedItems.length; i++) {
    const ungroupedItems = returnItems
      .filter(
        item => selectedItems[i].productKey === item.productKey && selectedItems[i].isReturnable === item.isReturnable
      )
      .slice(0, selectedItems[i].quantity)
      .map(({ productKey, ...item }) => ({ ...item, reason: selectedItems[i].reason || '' }));

    items.push(...ungroupedItems);
  }

  return items;
}

export function transformToReturnItems(items: OrderItem[]): ReturnItem[] {
  return items.map(({ id, ...item }) => ({ ...item, orderItemId: id }));
}
