import React from 'react';
import styled from '@emotion/styled';

type Align = 'left' | 'center';

interface ContainerProps {
  align?: Align;
}

const Container = styled('div')<ContainerProps>`
  width: 100%;
  height: 64px;
  max-height: 64px;
  display: flex;
  justify-content: ${props => (props.align === 'left' ? 'flex-start' : 'center')};
  align-items: center;
  background-color: white;
`;

const Image = styled('img')`
  max-height: 30px;
`;

// parent flex container makes children 'display: block' which gives unwanted paddings
const Link = styled('a')`
  line-height: 0;
`;

interface Props {
  imageLink?: string;
  imageUrl?: string;
  align?: 'left' | 'center';
}

function Logo(props: Props) {
  return (
    <Container align={props.align}>
      <Link href={props.imageLink} target="_blank" rel="noopener noreferrer">
        <Image src={props.imageUrl} alt="logo" />
      </Link>
    </Container>
  );
}

export default React.memo(Logo);
