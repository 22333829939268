// eslint-disable-next-line
import React from 'react';
import styled from '@emotion/styled';
import colors from '../common/colors';

const StickyButtonContainer = styled('div')`
  width: 100%;
  background-color: ${colors.white};
  position: sticky;
  bottom: 0;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export default StickyButtonContainer;
