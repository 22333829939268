import { useEffect } from 'react';

import useRedirectTo from './useRedirectTo';
import { isUnauthorizedError } from '../apiHelpers';

function useUnauthorizedRedirect(error?: Error | null) {
  const redirectToLogin = useRedirectTo('/login');

  useEffect(() => {
    if (isUnauthorizedError(error)) {
      redirectToLogin();
    }
  }, [error, redirectToLogin]);
}

export default useUnauthorizedRedirect;
