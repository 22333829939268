import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../common/Button';
import { useSettings } from '../../hooks/useSettings';
import { SubmitState } from '../../hooks/useReturnRequestActions';

type Props = {
  disabled?: boolean;
  submitState: SubmitState;
  onClick: () => void;
};

function SubmitReturnButton(props: Props) {
  const { disabled, submitState, onClick } = props;
  const { t } = useTranslation();
  const { accentColor } = useSettings();

  return (
    <Button
      name="createReturn"
      fullWidth
      color={accentColor}
      onClick={onClick}
      isLoading={submitState.isPending}
      disabled={disabled || submitState.isPending}
    >
      {t(submitState.error ? 'tryAgainButton' : 'createReturnButton')}
    </Button>
  );
}

export default React.memo(SubmitReturnButton);
