import React from 'react';
import { useTranslation } from 'react-i18next';

import ErrorView from './ErrorView';
import { useSettings } from '../../hooks/useSettings';
import { Order } from '../../types';

type Props = {
  order?: Order;
};

function ReturnExpired(props: Props) {
  const { order } = props;
  const { returnPeriod } = useSettings();
  const { t } = useTranslation();

  return <ErrorView order={order} message={t('returnPeriodExpiredMessage', { returnPeriod })} />;
}

export default ReturnExpired;
