import React, { ReactElement } from 'react';

type Props = {
  children: ReactElement[];
  selected: string;
};

function Switch(props: Props) {
  const { children, selected } = props;

  for (let i = 0; i < React.Children.count(children); i++) {
    if (children[i].key === selected) {
      return children[i];
    }
  }

  return null;
}

export default React.memo(Switch);
