import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import { redirectToExternalPortal } from '../../externalRedirectHelpers';
import { TextTrans } from '../common/Text';
import { ReturnRequest } from '../../types';

const Container = styled(TextTrans)`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

type Props = {
  submitState: { submittedReturnRequest?: ReturnRequest };
};

function ExternalRedirect({ submitState }: Props) {
  const { externalRedirectLink } = submitState.submittedReturnRequest || {};
  useEffect(() => {
    if (externalRedirectLink) {
      redirectToExternalPortal(externalRedirectLink);
    }
  }, [externalRedirectLink]);

  return <Container>Redirecting to external portal</Container>;
}

export default React.memo(ExternalRedirect);
