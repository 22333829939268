// IMPORTANT: fontHelpers is copypasted from `@sendwise/font-helpers` package

const customFonts = {
  google: [
    'Roboto',
    'Open Sans',
    'Lato',
    'Montserrat',
    'Roboto Condensed',
    'Source Sans Pro',
    'Oswald',
    'Raleway',
    'Merriweather',
    'PT Sans',
    'Roboto Slab',
    'Noto Sans',
    'Poppins',
    'Ubuntu',
  ],
};

/* eslint-disable quote-props */
const fontCategories: { [font: string]: string } = {
  // standard
  Helvetica: 'sans-serif',
  Arial: 'sans-serif',
  'Arial Black': 'sans-serif',
  'Comic Sans': 'sans-serif',
  'Courier New': 'monospace',
  Georgia: 'serif',
  Impact: 'sans-serif',
  Charcoal: 'sans-serif',
  'Lucida Console': 'monospace',
  'Lucida Sans Unicode': 'sans-serif',
  'Lucida Grande': 'sans-serif',
  'Palatino Linotype': 'serif',
  'Book Antiqua': 'serif',
  Palatino: 'serif',
  Tahoma: 'sans-serif',
  Geneva: 'sans-serif',
  Times: 'serif',
  'Times New Roman': 'serif',
  'Trebuchet MS': 'sans-serif',
  Verdana: 'sans-serif',
  Monaco: 'sans-serif',

  // google
  Roboto: 'sans-serif',
  'Open Sans': 'sans-serif',
  Lato: 'sans-serif',
  Montserrat: 'sans-serif',
  'Roboto Condensed': 'sans-serif',
  'Source Sans Pro': 'sans-serif',
  Oswald: 'sans-serif',
  Raleway: 'sans-serif',
  Merriweather: 'serif',
  'PT Sans': 'sans-serif',
  'Roboto Slab': 'serif',
  'Noto Sans': 'sans-serif',
  Poppins: 'sans-serif',
  Ubuntu: 'sans-serif',
};

const categoryFallbackFonts: { [cat: string]: string } = {
  'sans-serif': 'Helvetica',
  serif: 'Times New Roman',
  monospace: 'Courier New',
};

const fallbackFonts = Object.values(categoryFallbackFonts);

export function getFontFallback(font: string): string {
  const category = getFontCategory(font);
  return `${categoryFallbackFonts[category]}, ${category}`;
}

export function getFontCategory(font: string): string {
  return fontCategories[font] || 'sans-serif';
}

export function getFontFamilyWithFallback(font: string) {
  const category = getFontCategory(font);

  if (fallbackFonts.indexOf(font) !== -1) {
    // we don't need to repeat font name for fallback fonts
    return `${font}, ${category}`;
  }

  return `${font}, ${categoryFallbackFonts[category]}, ${category}`;
}

export function isGoogleFont(font: string): boolean {
  return customFonts.google.indexOf(font) !== -1;
}
